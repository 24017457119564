<script>


import { HashConnect } from 'hashconnect';
import axios from 'axios';
import CONFIG from '../config/config.js'
import '../css/Navbar.css'
import { useAccountStore } from '../stores/accountData';


export default {
       data() {
      return {
            initData: '',
            existingPairs: '',
            status: {
                connected: false,
                balanceUpdate: false
            },
            userData: {
                accountID: '',
                solAddress: '',
                topic: '',
                pairingString: '',
                wallets: '',
                savedPairings: false,
                foundWallets: false,
                provider: '',
                signer: '',
                tokenBalance:0
            },
            transactionData: {
                data: []
            },
            hc: '',
            error: {
                display: false,
                message: ''
            },
            currentType: '',
            accountStore: useAccountStore(),
        }
    },

    methods: {

        init: async function () {

            this.hc = new HashConnect()
            let appMetaData = {
                name: "HMNKY dApp",
                description: "Stake Monkeys",
                // logo: '/logo.png'
            }
            this.initData = await this.hc.init(appMetaData, CONFIG.currentNetwork, true);
            
            this.userData.pairingString = this.initData.pairingString
            if (this.initData.savedPairings.length > 0) {

                this.currentPair = this.initData.savedPairings[0]

                this.initData.savedPairings.forEach((pairing) => {
                    let topic = pairing.topic
                    let network = pairing.network
                    if (network == CONFIG.currentNetwork) {
                        this.userData.savedPairings = true
                    } else {
                        this.hc.disconnect(topic)
                        this.currentPair = this.initData.savedPairings[0]
                        this.accountStore.updateAccount("")
                        this.accountStore.updateSigner("")
                        this.accountStore.updateStatus(false)
                    }

                })

                if (this.initData.savedPairings[0].network == CONFIG.currentNetwork) {
                    this.currentPair = this.initData.savedPairings[0]
                    this.userData.accountID = this.currentPair.accountIds[0]
                    this.userData.topic = this.currentPair.topic
                    this.accountStore.updateAccount(this.userData.accountID)
                    this.userData.provider = this.hc.getProvider(CONFIG.currentNetwork, this.userData.topic, this.userData.accountID)
                    this.userData.signer = this.hc.getSigner(this.userData.provider)
                    this.accountStore.updateSigner(this.userData.signer)
                    console.log(this.initData.savedPairings[0].accountIds[0])
                    // await this.getBalance()
                    // this.status.connected = true
                    // this.accountStore.updateStatus(true)
                }

            }
            this.hc.foundExtensionEvent.once(async (walletMetaData) => {
                this.userData.wallets = walletMetaData
                this.userData.foundWallets = true
            })

            this.hc.pairingEvent.once(async (pairingMeta) => {
                
                if (pairingMeta.network != CONFIG.currentNetwork) {
                    this.error.display = true
                    this.error.message = `Looks Like You Connected On The Wrong Network. Please Switch to Hedera Hashgraph ${CONFIG.currentNetwork}`
                    this.hc.disconnect(pairingMeta.topic)
                }
                this.status.connected = true
                this.accountStore.updateStatus(true)
                
                this.currentPair = this.hc.hcData.pairingData
                // this.siteElements.modal.hide()
                this.userData.topic = pairingMeta.topic
                this.userData.accountID = pairingMeta.accountIds[0]
                this.accountStore.updateAccount(this.userData.accountID)
                await this.getBalance()
                
                this.userData.provider = this.hc.getProvider(CONFIG.currentNetwork, this.userData.topic, this.userData.accountID)
                this.userData.signer = this.hc.getSigner(this.userData.provider)
                this.accountStore.updateSigner(this.userData.signer)

            })
            console.log('initialized', this.userData)

            

        },

        initConnect: async function () {
            // let connection = this.hc.connect()

            this.existingPairs = this.initData.savedPairings
            
            if (!this.initData.savedPairings.length > 0) {
                if (this.userData.foundWallets == false) {
                    // this.siteElements.modal.show()

                } else {
                    await this.hc.connectToLocalWallet(this.initData.pairingString, this.userData.wallets)
                    this.currentPair = this.hc.hcData.pairingData[0]
                }
            } else {
                if (this.initData.savedPairings[0].network == CONFIG.currentNetwork) {
                    this.currentPair = this.initData.savedPairings[0]
                    this.userData.accountID = this.currentPair.accountIds[0]
                    this.status.connected = true
                    this.accountStore.updateStatus(true)
                    this.userData.topic = this.currentPair.topic
                    this.accountStore.updateAccount(this.userData.accountID)
                    this.userData.provider = this.hc.getProvider(CONFIG.currentNetwork, this.userData.topic, this.userData.accountID)
                    this.userData.signer = this.hc.getSigner(this.userData.provider)
                    this.accountStore.updateSigner(this.userData.signer)
                    await this.getBalance()
                } else {
                    this.initData.display = true
                    this.error.message = `Looks Like You Connected On The Wrong Network. Please Switch to Hedera Hashgraph ${CONFIG.currentNetwork}`
                    this.initData.savedPairings.forEach((pairing) => {
                        let topic = pairing.topic
                        this.currentPair = this.initData.savedPairings[0]
                        this.hc.disconnect(topic)

                    })
                }

            }

        },

        getBalance: async function (){

            const postUrl = `${CONFIG.mirrorNodeUrl[CONFIG.currentNetwork]}${CONFIG.balanceUrl}${this.userData.accountID}`

	        const response = await axios.get(postUrl);

            if(response.data.balances.length!=0){
                const rawBalance = response.data.balances[0].balance
                this.userData.tokenBalance= (rawBalance/10**CONFIG.decimal).toFixed(2)
                this.accountStore.updateBalance((rawBalance/10**CONFIG.decimal).toFixed(2))
            }
            
            this.status.balanceUpdate=true

        },

        connectWallet: async function () {
            console.log('connect wallet event')
            await this.initConnect()
        },

        disconnectWallet: function () {
            if (this.existingPairs.length > 0) {
                this.hc.disconnect(this.existingPairs.topic)
                this.currentPair = this.initData.savedPairings[0]
                console.log('disconnected')
                this.accountStore.updateAccount("")
                this.accountStore.updateSigner("")

            } else {
                console.log('no existing connection detected')

            }
            this.status.connected = false
            this.accountStore.updateStatus(false)
            this.status.waiting = false
        },
        
        toggleConnection: async function () {
            if (this.status.connected) {
                await this.disconnectWallet();
            } else {
                await this.connectWallet();
            }
        },
        closeNavbar() {
      // Close the mobile navbar by unchecking the checkbox
        const checkbox = document.querySelector('.menuToggle input[type="checkbox"]');
        if (checkbox) {
            checkbox.checked = false;
        }
        }

    },
    mounted() {
    this.init(); 
    
},

  name: 'NavBar'
}

</script>

<template>
    <nav role="navigation">
        <div class="navbar">
            <div class="menuwrapper">
                <div class="brandwrapper">
                    <div class="brand">HMNKY</div>
                </div>
                <div class="menuToggle">
                    <input type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul class="menu">
                            <div class="listwrapper">
                            
                                <li @click="closeNavbar">
                                    <RouterLink aria-current="page" to="/"><a :class="{ active: $route.path === '/' }" aria-current="page" to="/">HOME</a></RouterLink>
                                </li>
                               
                                <li @click="closeNavbar">
                                    <RouterLink aria-current="page" to="/stake"><a :class="{ active: $route.path === '/stake' }">STAKING</a></RouterLink>
                                </li>

                                <li @click="closeNavbar">
                                    <RouterLink  aria-current="page" to="/gallery"><a :class="{ active: $route.path === '/gallery' }">HALL OF SHAME</a></RouterLink> 
                                </li>
                                
                                <li @click="closeNavbar">
                                    <RouterLink  aria-current="page" to="/mint"><a :class="{ active: $route.path === '/mint' }">MINT</a></RouterLink> 
                                </li>

                                    <div class="isDisabledList">
                                        <li @click="closeNavbar">
                                            <a class="isDisabled" href="https://sentx.io/nft-marketplace/0.0.1006183">BUY</a>
                                        </li>

                                        <li @click="closeNavbar">
                                            <a class="isDisabled" href="https://docs.hederamonkeys.com/">GITBOOK</a>
                                        </li>

                                        <li @click="closeNavbar">
                                            <a class="isDisabled" href="https://discord.gg/Mn9nD4tU2a">DISCORD</a>
                                        </li>

                                        <li @click="closeNavbar">
                                            <a class="isDisabled" href="https://twitter.com/HMNKYs">TWITTER</a>
                                        </li>

                                        <li @click="closeNavbar">
                                            <a class="isDisabled" href="https://www.youtube.com/watch?v=Cs0nma2ioAY&t=59s">YOUTUBE</a>
                                        </li>
                                    </div>

                                <li class="connect-list-item">
                                <div class="connect-text">
                                    <button class="button" @click="toggleConnection" :title="status.connected ? 'DISCONNECT' : ''">
                                    {{ status.connected ? userData.accountID : 'CONNECT WALLET' }}
                                    </button>
                                </div>
                                <div class="connect-svg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#87da7a" class="bi bi-wallet-fill" viewBox="0 0 16 16">
                                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                    <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"/>
                                    </svg>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>
